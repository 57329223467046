import React, { Component, Fragment } from 'react'
import { Router, Route, Switch } from "react-router";
import HomePage from '../pages/HomePage';
import { Routes } from "react-router-dom";
import NotFound from '../components/NotFound/NotFound';
import AboutPage from '../pages/AboutPage';
import AllCorusePage from '../pages/AllCorusePage';
import AllServicePage from '../pages/AllServicePage';
import ContactPage from '../pages/ContactPage';
import CourseDetailsPage from '../pages/CourseDetailsPage';

import PageNotFound from '../pages/PageNotFound';
import PortfolioPage from '../pages/PortfolioPage';
import PrivacyPage from '../pages/PrivacyPage';
import ProjectDetailPage from '../pages/ProjectDetailPage';
import RefundPage from '../pages/RefundPage';
import TremsPage from '../pages/TremsPage';

class AppRouter extends Component {
     render() {
          return (
               <Fragment>



                    <Routes>
                         <Route exact path="/" element={<HomePage />} />
                         <Route exact path="/service" element={<AllServicePage />} />
                         <Route exact path="/course" element={<AllCorusePage />} />
                         <Route exact path="/porfolio" element={<PortfolioPage />} />
                         <Route exact path="/about" element={<AboutPage />} />
                         <Route exact path="/contact" element={<ContactPage />} />

                         <Route exact path="/refund" element={<RefundPage />} />
                         <Route exact path="/trems" element={<TremsPage />} />
                         <Route exact path="/privacy" element={<PrivacyPage />} />

                         <Route exact path="/projectdetails/:projectID/:projectName" element={<ProjectDetailPage />} />
                         <Route exact path="/coursedetails/:courseID/:courseName" element={<CourseDetailsPage />} />

                         <Route element={<PageNotFound />} />

                    </Routes>

               </Fragment>
          )
     }
}

export default AppRouter
