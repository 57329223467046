import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Header from './common/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
// import '../src/assets/css/custom.css';
// import '../src/assets/css/fontawesome.css';
// import '../src/assets/css/animate.min.css';
// import '../src/assets/css/placeholder-loading.min.css';
import axios from 'axios';

// // set main base url 
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api';
axios.defaults.baseURL = 'https://wsadmin.softenginebd.com/api';
// Beare Token Save 
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

//নিচের অংশ পরে অ্যাড করেছি পূর্বের অংশ ছিল পরবর্তীতে এটা ঠিক করা হবে যে কি করতে হবে
//কম্পনেন্ট didimount e alert দুইবার দেখা এই কারণে
// root.render(
//   <>
//     {/* <Header /> */}
//     <App />
//   </>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
