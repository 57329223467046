import axios from 'axios';

//মূল বিষয় হলো post এবং get এর জন্য আলাদা একটি জায়গা ক্রিয়েট করা হয়েছে যেখান থেকে রেসপন্স ডাটা কে রিসিভ করা হয় এবং ডাটা পোস্ট করা হয় 
//সরাসরি পেজের মধ্যে ডাটা গেট এবং পোস্ট না করে এক্ষেত্রে আলাদা ক্লাস RestClient তৈরি করা হয়েছে post and get request পরিচালনা করার জন্য 
//RestClient হলো একটি JavaScript ক্লাস, যেটি Axios লাইব্রেরি ব্যবহার করে API এর সাথে HTTP অনুরোধগুলি (GET এবং POST) পরিচালনা করে।
//Axios ইমপোর্ট করা হয়েছে: Axios হল একটি জনপ্রিয় HTTP ক্লায়েন্ট যা React বা অন্য JavaScript প্রোজেক্টে API কলের জন্য ব্যবহৃত হয়।
//getUrl: যে URL থেকে ডেটা রিট্রিভ করতে হবে।
//axios.get(getUrl): দেওয়া URL থেকে ডেটা আনে।
//.then(response => { return response.data; }): ডেটা সফলভাবে এলে সেটি রিটার্ন করে।
//.catch(error => { return null; }): কোনো সমস্যা হলে null রিটার্ন করে।
//postUrl: যে URL-এ ডেটা পাঠাতে হবে।
//postJson: পাঠানোর ডেটার JSON অবজেক্ট।
//config: একটি হেডার কনফিগারেশন সেট করে, যেখানে কন্টেন্ট টাইপ application/x-www-form-urlencoded দেওয়া আছে।
//axios.post(postUrl, postJson, config): পোস্ট রিকোয়েস্টের মাধ্যমে ডেটা পাঠায়।
//
class RestClient {

     static GetRequest = (getUrl)=>{
          return axios.get(getUrl).then(response=>{
               return response.data;
          }).catch(error=>{
               return null;
          });
     }



     static PostRequest = (postUrl,postJson)=>{
          let config={
               headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
          }

          return axios.post(postUrl,postJson,config).then(response=>{
               return response.data;
          }).catch(error=>{
               return null;
          });
     }



      
}

export default RestClient
