import React, { Component, Fragment } from 'react';

import { Button } from 'react-bootstrap';

import { BrowserRouter } from 'react-router-dom';

import AppRoute from './route/AppRoute';
import AppRouter from './router/AppRouter';

class App extends Component {
  // state = {
  //   count: 0,
  // };

  // increase = () => {
  //   this.setState({ count: this.state.count + 2 });
  // };

  render() {
    return (
      <Fragment>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>

      </Fragment>
    );
  }
}

export default App;



